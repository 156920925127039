@import '../../../commonStyle/var';
@import '../../../commonStyle/tool';
@import './var';

html body {
    display: flex;
    min-width: 1200px;
    width: 100%;
}

// header样式的定义
.white-header {
    background-color: #fff;
    color: #d7a564;
    overflow: hidden;
    box-shadow: 0 2px 8px 0 rgba(2, 30, 7, 0.1);
    .right-nav {
        a {
            color: #4c4945;
        }
        a.active,a:hover {
            color: #d7a564;
        }
    }
    .auto-middle {
        margin-top: 0px;
        a.import-btn {
            // margin-top: 8px;
            // width: 120px;
            // height: 38px;
            background-image: linear-gradient(90deg, 
                #f4cb99 0%, 
                #ffbf6d 100%), 
            linear-gradient(
                #e9b970, 
                #e9b970);
            background-blend-mode: normal, 
                normal;
            color: #fff;
        }
    } 
}
.fix {
    position: fixed;
    z-index: 99;
}
.opacity {
    transition: background @transitionTime;
    background-color: transparent;
    box-shadow: none;
}
.color-header {
    background-color: transparent;

    // background-color: #d7a564;
    color: #fff;
    .right-nav {
        a.active,a:hover {
            display: block;
            // height: 50px;
            border-bottom-color: #fff !important;
            line-height: 50px;
        }
    }
    .auto-middle {
        margin-top: 30px;
    }
    .import-btn {
        background-color: #ffffff;
	    box-shadow: 0px 1px 6px 0px 
		    rgba(182, 132, 46, 0.2);
        color: #d7a564;
    }
}
#top {
    transition: all @transitionTime;
    width: 100%;
    position: fixed;
    z-index: 999;
    .auto-middle {
        height: 88px;
        width: 1200px;
        justify-content: space-between;
        display: flex;
        align-items: center;
        .logo {
            a {
                width: 184px;
	            height: 54px;
            }
        }
        .right-nav {
            height: 54px;
            display: flex;
            .context {
                margin-right: 62px;
                display: flex;
                // width: 596px;
                height: 54px;
                align-items: center;
                // justify-content: space-between;
                justify-content: flex-end;
                a {
                    border-bottom-width: 2px;
                    border-bottom-style: solid;
                    border-bottom-color: transparent;
                    text-align: center;
                    font-size: 16px;
                    margin-left: 55px;
                }
            }
            .import-btn {
                margin-top: 8px;
                width: 120px;
                height: 38px;
                text-align: center;
                line-height: 38px;
                border-radius: 19px;
            }
        }
        
    }
}
// #header .container {
//     width: 1200px;
//     margin: 47px auto 160px auto;
// }

// 头部
#header {
    position: relative;
    width: 100%;
    height: 100vh;
    min-height: 700px;
    background: url(../../../../img/pc/bg-logo.png) no-repeat;
    background-color: @brown;
    background-position: center;
    .bottom-line {
        position: absolute;
        bottom: 21px;
        right: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        .small-line {
            width: 134px;
            height: 1px;
            background-color: #ffffff;
            margin-right: 11px;
        }
        .text {
            font-size: 14px;
            color: #fff;
        }
    }
    .botton-bg {
        width: 100%;
        // height: 100%;
        position: absolute;
        left: 0px;
        bottom: 0px;
        img {
            display: inline-block;
            width: 100%;
            height: auto;
        }
    }
    .container {
        z-index: 999;
        position: absolute;
        top: 56%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.container {
    .container-bottom {
        position: relative;
        z-index: 2;
        .line{
            width: 60px;
            height: 4px;
            background-color: #fff;
            margin: 0 auto 40px auto;
        }
        .jwn-award {
            h6{
                color: #fff;
                font-size: 24px;
                text-align: center;
            }
            .text {
                margin: 11px auto 33px auto;
                font-size: 20px;
                line-height: 2;
                text-align: center;
                color: #fff;
                text-shadow: 0px 1px 2px rgba(73, 44, 7, 0.24);
            }
            .one-text {
                width: 666px;
            }
            .two-text {
                width: 787px;
            }
            
        }
        p.text {
            text-align: center;
            margin: 0 auto;
            color: #fff;
            font-size: 14px;
            line-height: 1.5px;
        }
        
    }
}
.reporting {
    background: url(../../../../img/pc/header-btn-bg.png) no-repeat;
    width: 263px;
    height: 62px;
    margin: 0 auto 20px auto;
    text-align: center;
    line-height: 67px;
    color: #fff;
    font-size: 20px;
}
/* 了解金蜗牛奖 */
.video {
    margin: 0 auto;
    margin-top: 61px;
    margin-bottom: 109px;
    text-align: center;
    width: 740px;
    position: relative;
    > iframe{
        width: 100%;
        height: 440px;
    }
    /* 视频播放 */
    .video-btn {
        background: url(../../../../img/pc/video-btn.png);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
    }
}


/* 奖项日程 */
.schedule .title-style{
    margin-bottom: 120px;
}
// .schedule .date{
//     display: flex;
//     width: 1255px;
//     margin-bottom: 12px;
// }
// .schedule .date div{
//     flex: 1;
//     text-align: center;
    
// }

.schedule ul {
    padding: 0px 34px;
    width: 1200px;
    height: 167px;
    position: relative;
    display: flex;
    justify-content: space-around;
    &::before {
        position: absolute;
        content: '';
        width: 1255px;
        height: 141px;
        left: 50%;
        bottom: 0px;
        transform: translateX(-50%);
        background: url(../../../../img/pc/b.png);
    }
    li {
        width: 309px;
        height: 167px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        > div:nth-child(1) {
            font-size: 18px;
            color: #d09a58;
        }
        > div:nth-child(2) {
            font-size: 16px;
            color: #333333;
        }
    }
    li:not(:nth-child(1)) {
        margin-left: 103px;
    }
}




// .schedule .bg {
//     position: relative;
//     &::before {
//         content: '';
//         display: block;

//     }

//     width: 1255px;
//     height: 141px;
//     background: url(../../../../img/pc/b.png) no-repeat;
//     background-position: -27.5px center;
//     vertical-align: bottom;
//     display: flex;
//     align-items: flex-end;


// }

// .schedule .bg .instructor {
//     display: flex;
//     width: 1200px;
//     justify-content: space-between;
//     text-align: center;
//     padding: 0 35px;
//     box-sizing: border-box;
//     margin: 0 auto;  
    
// }
// .schedule .bg .instructor div{
//     font-size: 16px;
//     line-height: 1.5;
//     color: #333333;
//     width: 309.5px;
//     text-align: center;
// }
.schedule p.text-bottom {
    width: 1200px;
    text-align: center;
    color: #999;
    font-size: 16px;
    line-height: 1.5;
    margin-top: 60px;
    margin-bottom: 110px;
}
/* 奖项设置部分 */
.setting {
    width: 100%;
    background: url(../../../../img/pc/setting-bg.jpg) no-repeat;
    background-size: cover;
    // background: url(../../../../img/pc/setting-) no-repeat;
    // background-position: right;
    // background-color: #fafafa;
    height: 481px;
}
.setting .title-style {
    /* 奖项设置的标题 */
    padding-top: 100px;
}
.setting ul {
    display: flex;
    margin-top: 44px;
    align-items: flex-end;

}
.setting ul li {
    width: 194px;
    height: 163px;
    position: relative;
    cursor: pointer;
    transition: all @transitionTime;
    // 修改
    margin-right: 57px;
    
}
// 修改
.setting ul li:nth-child(5n) {
    margin-right: 0px;
}

.setting ul li:hover {
    transform: scale(1.05); 
}
.setting ul li.active-pic {
    position: relative;
}
.setting ul li span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    white-space: nowrap;
    color: #ffffff;
    font-size: 18px;
    line-height: 1.5;
    font-weight: bold;
    text-align: center;
}
.setting ul li:nth-child(1) {
    /* 奖项设置的图片 */
    background: url(../../../../img/pc/cup_01.png) no-repeat;
}
.setting ul li:nth-child(2) {
    /* 奖项设置的图片 */
    background: url(../../../../img/pc/cup_02.png) no-repeat;
}
.setting ul li:nth-child(3) {
    /* 奖项设置的图片 */
    background: url(../../../../img/pc/cup_03.png) no-repeat;
}
.setting ul li:nth-child(4) {
    /* 奖项设置的图片 */
    background: url(../../../../img/pc/cup_04.png) no-repeat;
}
.setting ul li:nth-child(5) {
    /* 奖项设置的图片 */
    background: url(../../../../img/pc/cup_05.png) no-repeat;
}
.setting ul li {
    div {
        width: 26px;
        height: 4px;
        background-color: #ffffff;
        position: absolute;
        left: 50%;
        bottom: 52px;
        transform: translateX(-50%);
    }
}
.setting .auto-middle div.text {
    margin-top: 37px;
    text-align: center;
    p {
        display: none;
        font-family: MicrosoftYaHei;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 30px;
        letter-spacing: 0px;
        color: #666666;
    }
}
// 奖项评委
.judges {
    width: 1346px !important;
    position: relative;
    margin-top: 130px;
    margin-bottom: 127px;
    .judges-pic {
        width: 1346px;
        // height: 228px;
        margin: 0 auto;
        position: relative;
        .swiper-container {
            width: 1200px;
            padding-top: 60px;
            box-sizing: content-box;
            // height: 228px;
            text-align: center;
        }
        .swiper-button-prev {
            background: url(../../../../img/pc/left-btn.png) no-repeat;
            font-size: 38px !important;
            color: #dbdbdb;
        }
        .swiper-button-next {
            background: url(../../../../img/pc/right-btn.png) no-repeat;
        }
        .swiper-slide{
            .flex;
            .align-center;
            .flex-direction;
            .box {

                position: relative;

                width: 166px;
                height: 166px;
                box-sizing: border-box;
                padding: 1px;
                border-radius: 50%;
                background-image: linear-gradient(90deg, 
                    #e9bf8d 0%, 
                    #e59e42 100%);
	            border-image-slice: 1;
                position: relative;
                // img {
                //     position: absolute;
                //     left: 50%;
                //     top: 50%;
                //     transform: translate(-50%, -50%);
                // }
                div.circle {
                    width:100%; 
                    height:100%; 
                    padding: 5px;
                    border-radius:50%; 
                    background:#fff; 
                    overflow: hidden;
                    .flex-center;
                    > img{
                        border-radius: 50%;
                        width: 100%;
                        height: 100%;
                    }
                }
                // .popup {
                //     position: absolute;
                //     top: 50%;
                //     left: 142px;
                //     z-index: 999;
                //     box-shadow: 0px 2px 12px 0px 
		        //     rgba(45, 33, 3, 0.12);
	            //     border-radius: 8px;
                //     transform: translateY(-50%);
                //     display: none;
                //     width: 390px;
                //     padding: 30px 30px 20px 30px;
                //     background-color: #fff;
                //     .title {
                //         display: flex;
                //         align-items: center;
                //         margin-bottom: 12px;
                //         .name {
                //             font-weight: bold;
                //             font-size: 18px;
                //             color: #333;
                //             margin-right: 12px;
                //             margin-top: 0px
                //         }
                //         .hospital {
                //             font-size: 14px;
                //             color: #999;
                //         }
                //     }
                //     > p {
                //         font-size: 16px;
                //         color: #666;
                //         line-height: 1.9;
                //         text-align: left;
                //     }
                // }
            }
            .box:hover {
                cursor:pointer;
                .circle {
                    background-color: #d7a564;
                }
                // .popup {
                //     display: block;
                // }
            }
            .name {
                margin-top: 10px;
                font-size: 18px;
                color: #333333;
                font-family: "MicrosoftYaHei";
            }
            p.hospital {
                margin-top: 6px;
                font-size: 14px;
                color: #999;
                padding: 0 5px;
            }
        }

    }
    
}


.popup {
    position: absolute;
    top: 50%;
    // left: 142px;
    z-index: 999;
    box-shadow: 0px 2px 12px 0px 
    rgba(45, 33, 3, 0.12);
    border-radius: 8px;
    transform: translateY(-50%);
    display: none;
    width: 390px;
    padding: 30px 30px 20px 30px;
    background-color: #fff;
    .title {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        .name {
            font-weight: bold;
            font-size: 18px;
            color: #333;
            margin-right: 12px;
            margin-top: 0px
        }
        .hospital {
            font-size: 14px;
            color: #999;
        }
    }
    > p {
        font-size: 16px;
        color: #666;
        line-height: 1.9;
        text-align: left;
    }
}

// 最新获奖者


// 主办单位模块
div.organisers {
    background: url(../../../../img/pc/organisers-bg.jpg) no-repeat;
    background-size: cover;


    // background: url(../../../../img/pc/organisers-bg.jpg) no-repeat;
    color: #fff;
    box-sizing: border-box;
    padding-top: 102px;
    .small-text {
        font-family: ArialMT;
        font-size: 14px;
        margin-top: 5px;
    }
    .auto-middle {
        position: relative;
        margin-top: 0px;
    }
    .common-line {
        background-color: #fff;
    }
    .small-text {
        color: #fff;
    }
    .white-space {
        position: relative;
        bottom: -27px;
        margin-top: 47px;
        width: 1200px;
        // height: 340px;
        box-shadow: 0px 2px 12px 0px 
            rgba(45, 33, 3, 0.08);
        border-radius: 10px;
        opacity: 0.98;
        background-color: #fefdfc;
        background-color: #fff;
        opacity: 1;
        padding: 27px 26px;
        z-index: 50;
        .m-context {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #fefdfc;
            padding: 53px 43px 54px 50px;
            .l-logo {
                width: 224px;
                height: 138px;
                .flex-center;
            }
            .article-content p{
                width: 794px;
                z-index: 6;
                color: #666666;
                font-size: 16px;
                text-align: left;
                line-height: 2.25;
                a {
                    color: #1e8ee4;
                }
            }
            
        }
        img.rectangular-pic {
            position: absolute;
        }
        img.rectangular-pic:nth-child(1){
            top: 20px;
            left: 20px;
        }
        img.rectangular-pic:nth-child(2) {
            top: 20px;
            right: 20px;
        }
        img.rectangular-pic:nth-child(3){
            bottom: 20px;
            left: 20px;
        }
        img.rectangular-pic:nth-child(4) {
            bottom: 20px;
            right: 20px;
        }
    }
     
}

  
// 合作伙伴
.partner {
    margin-top: 105px;
    .small-text {
        margin-top: 3px;
    }
    .partner-url {
        margin-top: 16px;
    }
    .host-parter {
        .tab-list {
            margin-top: 156px;
            .flex-center;
            width: 100%;
            li {
                margin-left: 118px;
            }
            & li:nth-child(1) {
                margin-left: 0px;
            }
        }
    }
}
// 赞助商
.sponsors {
    ul {
        display: flex;
        justify-content: center;
        margin: 56px auto 30px auto;
        width: 870px;
        li {
            margin-left: 118px;
            font-size: 20px;
            color: #66625c;
            height: 39px;
            box-sizing: border-box;
            text-align: center;
            position: relative;
            div {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                margin-top: 15px;
                display: none;
            }
        }
        & li:nth-child(1) {
            margin-left: 0px;
        }
        li.active {
            color: #d7a564;
            div {
                display: block;
                width: 50px;
                height: 2px;
                background-color: #d7a564;
            }
        }
    }
    div.url a:nth-child(5) {
        margin-right: 0px;
    }
}
//支持媒体
.support-medio {
    margin-top: 72px;
    .title-style {
        margin-top: 46px;
        .small-text {
            margin-top: 3px;
        }
    }
    .audio-url {
        margin-top: 19px;
    }
    
}
 
#footer {
    overflow: hidden;
    margin-top: 110px;
    & .bgc:nth-child(1) {
        background-color: #f2f2f2;
    }
    & .bgc:nth-child(2) {
        background-color: #e6e6e6;
    }
    .top {
        height: 248px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .l-logo {
            padding-right: 150px;
            
        }
        .m-text {
            flex-basis: 100%;
            .committee {
                padding: 0px 40px;
                font-size: 18px;
                color: #1a1a1a;
            }
            .methods {
                border-right: 1px solid #e0e0e0;
                border-left: 1px solid #e0e0e0;
                padding: 0px 40px;
                > p {
                    margin-top: 15px;
                    font-size: 14px;
                    color: #4d4d4d;
                    > a {
                        font-size: 14px;
                        color: #4d4d4d;
                    }
                }
            }
        }
        .r-code {
            text-align: center;
            padding-left: 141px;
            
            .code {
                width: 134px;
                height: 134px;
                padding: 9px;
                > img {
                    width: 100%;
                    height: 100%;
                }
            }
            .t-title {
                font-size: 18px;
                color: #1a1a1a;
                margin-bottom: 5px;
            }
        }
    }
    .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        a {
            font-size: 14px;
            color: #808080;
        }
        > .l {
            .center {
                margin-right: 33px;
            }
        } 
    }
}


// 新闻动态页面
#new-item {
    .news-top {
        width: 100%;
        height: 220px;
        background-color: #fafafa;
        display: flex;
        justify-content: center;
        align-items: center;
        .context {
            width: 1200px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // margin-top: 68px;
            h5 {
                font-size: 32px;
                color: #33312e;
            }
            .line {
                margin-top: 19px;
                width: 50px;
                height: 5px;
                background-color: #d7a564;
            }
        }
    }
    .list {
        ul {
            padding-top: 30px;
        }
        li {
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all @transitionTime;
            .middle {
                width: 952px;
                padding: 40px 30px;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #ededed;
                .left {
                    span {
                        font-size: 20px;
                        color: #333;
                    }
                    p {
                        margin-top: 22px;
                        width: 713px;
                        font-size: 14px;
                        color: #666;
                        line-height: 1.857;
                        display: -webkit-box;//必须结合的属性，将对象作为弹性盒子模型显示
                        -webkit-line-clamp: 2; //限制块元素显示文本的行数
                        -webkit-box-orient: vertical;//必须结合的属性，设置或检索盒对象的子元素的排列方式
                        overflow: hidden;
                    }
                }
                .right {
                    .mouth {
                        font-size: 36px;
                        color: #999;
                    }
                    .year {
                        margin-top: 9px;
                        font-size: 18px;
                        color: #999;
                    }
                }
            }

        }
        .li-a:hover {
            .middle {
                box-shadow: 0px 1px 10px 0px 
                rgba(0, 0, 0, 0.08);
                opacity: 0.98;
                border-bottom-color: transparent;     
            }
            .left {
                span {
                    color: #d7a564;
                }
            }
        }
    }
    .load {
        display: block;
        width: 1200px;
        margin: 0 auto;
        margin-top: 50px;
        div {
            font-size: 14px;
            color: #d7a564;
            text-align: center;
        }

    }
}


