@import '../../../commonStyle/var';
@import '../../../commonStyle/tool';
@import './var';

.container {
  width: 1200px;
  margin: 0 auto;
}
.sm-container{
  width: 950px;
  margin: 0 auto;
}
.as-a {
  cursor: pointer;
}
.base-v-list {
  .flex;
  .flex-wrap;
  > .item {
    background-color: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(9, 9, 9, 0.12);
    > .img,
    > .img-box {
      width: 100%;
      display: block;
    }
    .content {
      width: 100%;
      font-size: 16px;
      color: @black;
      padding: 26px 16px;
      text-align: left;
    }
  }
}
.banner-top {
  margin-bottom: 70px;
  background-color: #fafafa;
  padding: 0 20px;
  color: #33312e;
  height: 220px;
  width: 100%;
  font-size: 32px;
  .flex-center;
  .flex-direction;
  .title {
    position: relative;
    padding-bottom: 20px;
    z-index: 2;
    &::after {
      content: '';
      display: block;
      width: 50px;
      height: 5px;
      position: absolute;
      left: 50%;
      bottom: 0;
      background-color: @brown;
      transform: translateX(-50%);
    }
  }
  .desc {
    position: relative;
    z-index: 2;
    font-size: 14px;
    color: #f6e9cd;
    text-align: center;
    margin-top: 14px;
  }
  &.has-bg {
    position: relative;
    background-color: @brown;
    > .img {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
    }
    > .title {
      color: #fff;
      &::after {
        background-color: #fff;
      }
    }
  }
  &.mb-40 {
    margin-bottom: 40px;
  }
}
.btn {
  position: relative;
  border: 0px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 28px;
  font-size: 16px;
  height: 38px;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  overflow: visible;
  margin-left: initial;
  transform: translate(0px, 0px);
  margin-right: initial;
  &.block {
    width: 100%;
  }
  &.lg {
    height: 54px;
    font-size: 20px;
  }
  &.sm {
    font-size: 14px;
    height: 26px;
  }
  &.w-250 {
    width: 250px;
  }
  &.radius {
    border-radius: 1000px;
  }
  &[class*='line'],
  .cu-btn[class*='pure'] {
    background-color: transparent;
  }
  &.radius[class*='line']::after {
    border-radius: 1000px;
  }
  &[class*='bg-']::after {
    display: none;
  }
  &[class*='line']::after {
    content: ' ';
    display: block;
    width: 200%;
    height: 200%;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid currentColor;
    transform: scale(0.5);
    transform-origin: 0 0;
    box-sizing: border-box;
    border-radius: 2px;
    z-index: 1;
    pointer-events: none;
  }
}

.avatar-wrap {
  width: 166px;
  height: 166px;
  border: 1px solid @brown;
  padding: 5px;
  border-radius: 50%;
  > img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.hover-img-scale{
  .img-box{
    overflow: hidden;
    > img{
      transition: all @transitionTime;
    }
  }
  &:hover{
    .img-box{
      > img{
        transform: scale(1.2);
      }
    }
  }
}
.hover-title-scale{
  .title{
    transition: all @transitionTime;
  }
  &:hover{
    .title{
      transform: scale(1.2);
    }
  }
}

.tab-wrapper{
  .tab-content-list{
    .tab-content-item{
      display: none;
      &.active{

      }
    }
  }
}
// 定义四张图片左对齐
.four-left {
  font-size: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  a {
      width: 277px;
      height: 122px;
      border: solid 1px #ededed;
      box-sizing: border-box;
      padding: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 38px;
      img {
        max-width: 100%;
        max-height: 100%;
      }
  }
  & a:not(:nth-child(4n)) {
    margin-right: 30px;
  }
}


// 定义四张图片居中对齐
.four-middle {
  font-size: 0px;
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-left: -30px;
  a {
    width: 277px;
    height: 122px;
    border: solid 1px #ededed;
    box-sizing: border-box;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 38px;
    margin-left: 30px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}