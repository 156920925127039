@import "./var";
@import "./tool";


.text-blue{
    color: @blue;
}
.text-brown{
	color: @brown;
}

.bg-gray {
	color: #4d4d4d;
    background-color: #ededed;
}
.bg-disable {
	color: #fff;
	background-color: #cccccc;
}
.bg-white {
	background-color: #ffffff;
	color: @brown;
}
.bg-gradient-brown {
    background-image: linear-gradient(90deg, #f4cb99 0%, #ffbf6d 100%),
      linear-gradient(#ffffff, #ffffff);
    color: #fff;
}
.bg-brown{
	background-color: @brown;
	color: #fff;
}
.en{
	word-break: break-word;
}
.text-white{
	color: #ffffff;
}
.text-gray{
	color: @gray;
}
.text-light-black{
	color: @light-black;
}

// .popup-wrapper,.msgBox{
// 	.msgBoxContent,.article-content{
// 		p,div,span{
// 			word-break: break-word;
// 			text-align: justify;
// 			width: 100%;
// 		}
// 	}
// }
// .qs-popup-wrapper{
// 	padding: 50px 40px;
// 	width: 520px;
// 	background-color: #ffffff;
// 	.icon-close{
//     &.m{
//       z-index: 9;
//       position: absolute;
//       top: -40px;
//       right: 0;
//     }
// 	}
// 	.popup-header{
// 		padding: 0;
//     text-align: center;
//     margin-bottom: 28px;
//     > .en-title{
//       justify-content: center;
//       font-size: 18px;
//       color: #1a1a1a;
//       display: flex;
//       align-items: flex-end;
//       > span{
//         margin-left: 10px;
//         font-size: 14px;
//         color: @gray;
//       }
//     }
// 	}
// 	.popup-content{
// 		margin: 0;
// 		padding: 0;
//   }
//   .btn-group{
//     .icon-close{
//       position: static;
//     }
//   }
//   &.w-550{
//     width: 550px;
//   }
//   &.w-860{
//     width: 860px;
//     max-width: unset;
//   }
// }
// .hidden {
//     display: none !important;
// }