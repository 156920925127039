.award-list {
  width: 950px;
  margin: 0 auto;
  &-item {
    position: relative;
    z-index: 1;
    .align-center;
    .justify-between;
    background-color: #ffffff;
    padding: 30px;
    border-bottom: 1px solid @border;
    .img-box {
      margin-right: 26px;
    }
    .content {
      flex: 0 0 574px;
      .top {
        .flex;
        .align-center;
        .flex-wrap;
        .name {
          flex-shrink: 0;
          font-size: 20px;
          color: @black;
          margin-right: 13px;
        }
        .title {
          position: relative;
          height: 32px;
          line-height: 32px;
          font-size: 16px;
          color: #fff;
          z-index: 2;
          padding: 0 15px;
          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            background-image: url('../../../../img/pc/title.png');
            background-repeat: round;
          }
        }
      }
      .desc {
        margin-top: 26px;
        font-size: 14px;
        color: @light-black;
        line-height: 1.6;
      }
    }
    .btn {
      padding: 0 13px;
    }
    &:hover {
      box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.08);
      border-bottom-color: transparent;
      z-index: 2;
      .btn {
        background-color: @brown;
        color: #fff;
      }
    }
  }
}
.awards-module-title {
  margin-top: 100px;
  margin-bottom: 34px;
  text-align: center;
  font-size: 26px;
  color: #33312e;
  &.mt-60 {
    margin-top: 60px;
  }
  &.mt-114 {
    margin-top: 114px;
  }
}
.last-award-list {
  .flex;
  .flex-wrap;
  &-item {
    color: #fff;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    position: relative;
    font-size: 30px;
    width: 216px;
    margin-bottom: 30px;
    height: 100px;
    .flex-center;
    .title{
      z-index: 2;
    }
    &::before {
      content: '';
      position: absolute;
      display: block;
      z-index: -1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-image: url('../../../../img/pc/roll-bg.png');
    }
    &:not(:nth-child(5n)) {
      margin-right: 30px;
    }
  }
}

.best-case-wrap {
  background-color: #ffffff;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  display: block;
  width: 640px;
  margin: 0 auto;
  > .img {
    display: block;
    width: 100%;
  }
  .title {
    padding: 0 24px;
    height: 70px;
    line-height: 70px;
    font-size: 18px;
    text-align: center;
    color: @black;
  }
  & + .best-case-wrap{
    margin-top: 40px;
  }
}
.winner-case-list {
  > .item {
    margin-bottom: 30px;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    width: 216px;
    .img-box {
      width: 100%;
      height: 144px;
    }
    .content {
      line-height: 1.6;
      font-size: 16px;
      padding: 16px 16px;
      color: @black;
      > .title{
        .text-cut-2;
      }
    }
    &:not(:nth-child(5n)) {
      margin-right: 30px;
    }
  }
}

.page-detail {
  .title-wrap {
    padding-top: 76px;
    padding-bottom: 28px;
    border-bottom: 1px solid @border;
    margin-bottom: 50px;
    .title {
      font-weight: bold;
      font-size: 38px;
      color: @black;
      text-align: center;
      line-height: 1.4;
      margin-bottom: 23px;
      &.mb-40 {
        margin-bottom: 40px;
      }
    }
    .date {
      text-align: center;
      font-size: 14px;
      color: @gray;
    }
  }
}

.base-load-group{
  color: @brown;
  .flex-center;
  margin-top: 50px;
  font-size: 14px;
}