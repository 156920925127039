//所有项目的公共样式
@import 'var';

// 字母大写 
.big-english {
  text-transform: uppercase;
}


// 水平居中，垂直居中
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fr {
  float: right;
}
.fl {
  float: left;
}
.clear::after {
  content: '';
  display: block;
  clear: both;
}
/* 自定义类 */
.auto-middle {
  width: 1200px;
  margin: 110px auto 0 auto;
  transition: all @transition;
}
.title-style {
  text-align: center;
}
.title-container .common-line {
  /* position: absolute;
    left: 50%; */
  /* 画线 */
  display: inline-block;
  width: 20px;
  height: 2px;
  background-color: #d09a58;
}
.s-line-left {
  /* 定义左边的线的位置 */
  transform: translateY(-8.5px);
  margin-right: 8px;
}
.s-line-right {
  /* 定义右边的线的位置 */
  transform: translateY(-8.5px);
  margin-left: 8px;
}
.title-container .big-text {
  /* 定义大的文字--标题 */
  font-size: 26px;
  line-height: 1.5;
}
.title-style .small-text {
  /* 定义小的文字的标题 */
  margin-top: 3px;
  font-size: 14px;
  color: #999;
}

.text-pre-wrap {
  white-space: pre-wrap;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-cut {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.text-cut-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.text-cut-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.text-cut-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.text-cut-5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.flex {
  display: flex;
}
.flex-shrink {
  flex-shrink: 0;
}
.basis-xs {
  flex-basis: 20%;
}

.basis-sm {
  flex-basis: 40%;
}

.basis-df {
  flex-basis: 50%;
}

.basis-lg {
  flex-basis: 60%;
}

.basis-xl {
  flex-basis: 80%;
}

.flex-sub {
  flex: 1;
}

.flex-twice {
  flex: 2;
}

.flex-treble {
  flex: 3;
}

.flex-direction {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-center {
  align-items: center;
}

.align-stretch {
  align-items: stretch;
}

.self-start {
  align-self: flex-start;
}

.self-center {
  align-self: flex-center;
}

.self-end {
  align-self: flex-end;
}

.self-stretch {
  align-self: stretch;
}

.align-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}
.flex-center {
  .flex;
  .align-center;
  .justify-center;
}

.l-img-r-content {
  .flex;
  .justify-between;
  > .img,
  > .img-box {
    flex-shrink: 0;
  }
  .content {
    flex: 1;
  }
}
.hidden {
  display: none !important;
}
.text-overflow{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 170px;
}
// 定义五张图片的样式
.five-pic {
  font-size: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: calc(100% / 4);
      height: 104px;
      box-sizing: border-box;
      padding: 20px 15px;
      // margin-right: 5px;
      
      img {
          max-width: 100%;
          max-height: 100%;
      }
  }
}
.five-pic a:nth-child(5) {
  margin-right: 0px;
}

